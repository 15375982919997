<template>
  <div v-if="getMembersMeta.tabs" class="nav-bar">
    <h1 class="nav-bar__title">{{ $t('participants') }}</h1>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in getUserRole(getUser?.role).membersNavBar"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
          :class="['nav-bar__links-link', { active: $route.fullPath === link.path }]"
      >
        {{ $t(link.text) + count(link.status) }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <p class="nav-bar__text">{{ $t('all') }} {{ `(${getMembersMeta.total})` }} <img src="@/assets/svg/arrows/select-red.svg" alt="" /></p>
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in getUserRole(getUser?.role).membersNavBar"
              :to="link.path"
              :key="index"
              class="nav-bar__links-link"
              :class="['nav-bar__links-link', { active: $route.fullPath === link.path }]"
              @click.native="visibleSettings = false"
          >
            {{ $t(link.text) + count(link.status) }}
          </router-link>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
import { getUserRole } from "@/utils/user";
import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  components: {
    UiMenu: () => import('@/components/ui/UiMenu')
  },

  data() {
    return {
      getUserRole,
      visibleSettings: false,
    }
  },

   methods: {
     count(status) {
       if(status === 'All') {
         return this.getMembersMeta?.total ? ` (${this.getMembersMeta?.total})` : ` (0)`
       }
       else {
         return this.getMembersMeta?.tabs[status] ? ` (${ this.getMembersMeta?.tabs[status] })` : ` (0)`
       }
     }
   },

  computed: {
    ...mapGetters(['getUser', 'getMembersMeta'])
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  min-width: 154px;
  z-index: 48;
  background: #F5F5F5;

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__text {
    display: none;
    gap: 10px;

    @media (max-width: 1200px) {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #CE2121;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1200px) {
      display: none;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      transition: 0.3s;

      &.active {
        color: #CE2121;
      }
    }
  }
}
</style>
